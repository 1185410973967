$minWidth: (
	sm: 576px,
	md: 768px,
	lg: 1024px,
	xl: 1280px,
	_2xl: 1536px,
);
$container: (
	sm: 100vw,
	md: 768px,
	lg: 1024px,
	xl: 1280px,
	_2xl: 1536px,
);

// use max-width
$maxWidth: (
	sm: 575px,
	md: 767px,	
	lg: 1023px,
	xl: 1279px,
	_2xl: 1535px,
);

$transparent: transparent;
$current: currentColor;
$white: #FFF;
$black: #000;
$red: #F11B23;
$bluesapphire: #004F98;
$content: #5C6C7B;
$gray: #575656;
$alabaster: #F0EEE9;
$green: #14a44d;
$whisper: #F3F3F3;
$brightblue: #0286CF;
$header: #0B0B0B;
$line: #E1E1E1;
$copyright: #F0F0F0;
$blueline: #0076E3;
$bluesky: rgba(25, 164, 223, 0.15);

////////////////////////////////////////////////
@mixin rp($canvas) {
  @media screen and (max-width: #{$canvas}){
	@content;
  }
}

@mixin rpmin($canvas) {
  @media screen and (min-width: map-get($minWidth, #{$canvas})){
	@content;
  }
}
@mixin rpmax($canvas) {
  @media screen and (max-width: map-get($maxWidth, #{$canvas})){
      @content;
  }
}