@layer components {
  .container-full,
  .container-left,
  .container-right {
    @apply container;
  }
  .container-left,
  .container-right {
    max-width: 100dvw;
    @each $key, $size in $minWidth {
      @media (min-width: $size) {
        max-width: calc(
          (100vw - #{map-get($minWidth, $key)}) / 2 + #{map-get($minWidth, $key)}
        );
      }
    }
  }
  .container-right {
    margin-right: 0;
    padding-right: 0;
  }
  .container-left {
    margin-left: 0;
    padding-left: 0;
  }

  .container-full {
    max-width: 100dvw;
  }

  .svg[data-lazysrc]{
    opacity: 0;
  }
  

  .line-border{
    background-image: url('data:image/svg+xml,<svg width="124" height="2" viewBox="0 0 124 2" fill="none" xmlns="http://www.w3.org/2000/svg"><line x1="124" y1="1" y2="1" stroke="%23DF1E08" stroke-width="2" stroke-dasharray="10 10"/></svg>');
  }

  .side-left {
    width: calc(100% + var(--sidespace, 0));
    max-width: calc(100% + var(--sidespace, 0));
    margin-left: calc(var(--sidespace, 0) * -1);
    margin-right: 0px;
  }
  .side-right {
    width: calc(100% + var(--sidespace, 0));
    max-width: calc(100% + var(--sidespace, 0));
    margin-right: calc(var(--sidespace, 0) * -1);
    margin-left: 0px;
  }
  .side-full {
    width: calc(100% + var(--sidespace, 0) + var(--sidespace, 0));
    max-width: calc(100% + var(--sidespace, 0) + var(--sidespace, 0));
    margin-left: calc(var(--sidespace, 0) * -1);
    margin-right: calc(var(--sidespace, 0) * -1);
  }

  .clippath-industry{
    clip-path: ellipse(83% 100% at 50% 100%);
  }

  .child-full {
    & > * {
      width: 100%;
      height: 100%;
      border-radius: inherit;
    }
  }
  .child-frame {
    & > * {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: inherit;
    }
  }
  .fieldstyle {
    span {
      display: inline-block;
    }
  }
  .detail-post{
    color: $content;
    font-size: 15px;
    line-height: 1.4;

    @include rpmin(lg){
        font-size: 16px;
    }
    @include rpmin(xl){
        font-size: 18px;
    }

    h2{
        margin-bottom: .5rem;

        color: $red;
        font-weight: 600;
        font-size: 18px;
        
        @include rpmin(lg){
            font-size: 1.5rem;
        }
        
        @include rpmin(xl){
            font-size: 2rem;
        }
    }

    h3{
        margin-bottom: .5rem;

        color: $red;
        font-weight: 600;
        font-size: 16px;
        
        @include rpmin(lg){
            font-size: 18px;
        }
        
        @include rpmin(xl){
            font-size: 1.5rem;
        }
    }

    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      padding-bottom: 0.5rem;
    }

    span {
        display: inline-block;
    }

    .red{
        color: $red;
    }
    p{
        padding-bottom: .5rem;
    }

    ul {
        padding-bottom: .5rem;
    
        & > li {
          position: relative;
          padding-left: 1.5rem;
    
          &:not(:last-child) {
            padding-bottom: 10px;
          }
    
          & > ul {
            margin-top: 0.5rem;
            & > li {
              position: relative;
              padding-left: 1rem;
    
              & > ul {
                margin-top: 0.5rem;
    
                & > li {
                  position: relative;
                  padding-left: 0.5rem;
                }
              }
            }
          }
        }
    }

    img, svg, video, canvas, audio, iframe, embed, object{
      display: inline-block;
    }

    figure {
        padding-bottom: .5rem;
    
        figcaption {
          font-size: 13px;
        }
    }

    a {
        &:hover {
            color: $bluesapphire;
            outline: none;
            text-decoration: underline;
        }
    }
}

}
