@layer utilities {
    .ruled-grid{
        overflow: hidden;
        --line: #135CA0;
        --lineWidth: 1px;
        --gapx: 1.5rem;
        --gapy: 2rem;
        
        @include rpmin(lg){
            --gapx: 2.5rem;
            --gapy: 4rem;
        }

        @include rpmin(_2xl){
            --gapx: 5rem;
            --gapy: 5.25rem;
        }
        
        &>*{
            &::before, &::after{
                content: "";
                position: absolute;
            }

            &::before{
                top: 50%;
                left: calc(var(--gapx, 0px) / 2 * -1);
                border-left: var(--lineWidth, 1px) solid var(--line, #000);
                
                height: 150%;
                width: 0;
                transform: translateY(-50%);
            }
            &::after{
                top: calc(var(--gapy, 0px) / 2 * -1);
                left: 50%;
                border-top: var(--lineWidth, 1px) solid var(--line, #000);
                
                width: 150%;
                height: 0;
                transform: translateX(-50%);
            }
        }
    }

    .btn-arrow{
        &::after{
            content: "";
            display: inline-block;
            width: 10px;
            height: 10px;
            background-image: url('data:image/svg+xml,<svg width="10" height="10" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17 8.4996L8.5004 0L5.97831 2.52209L10.1712 6.71654L0 6.71654V10.2843L10.172 10.2843L5.97831 14.4787L8.5004 17.0016L17 8.4996Z" fill="white"/></svg>');
            transition-duration: 300ms;
            
            @include rpmin(md){
                background-image: url('data:image/svg+xml,<svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17 8.4996L8.5004 0L5.97831 2.52209L10.1712 6.71654L0 6.71654V10.2843L10.172 10.2843L5.97831 14.4787L8.5004 17.0016L17 8.4996Z" fill="white"/></svg>');
                width: 17px;
                height: 17px;
            }
        }
        &:hover{
            &.hover\:bg-white{
                &::after{
                    background-image: url('data:image/svg+xml,<svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17 8.4996L8.5004 0L5.97831 2.52209L10.1712 6.71654L-4.1878e-07 6.71654V10.2843L10.172 10.2843L5.97831 14.4787L8.5004 17.0016L17 8.4996Z" fill="%23F11B23"/></svg>');
                }
            }
            &::after{
                transform: rotate(-45deg);
            }
        }
        
    }

    .group:hover{
        .group-hover\:btn-arrow{
            &::after{
                transform: rotate(-45deg);
            }
        }
        .group-hover\:btn-arrow-red{
            &::after{
                background-image: url('data:image/svg+xml,<svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17 8.4996L8.5004 0L5.97831 2.52209L10.1712 6.71654L-4.1878e-07 6.71654V10.2843L10.172 10.2843L5.97831 14.4787L8.5004 17.0016L17 8.4996Z" fill="%23F11B23"/></svg>');
                transform: rotate(-45deg);
            }
        }
    }

    .group{
        &:hover{
            rect{
                fill:$red;
            }
        }
        &-svg-rect{
            rect{
                transition-duration: 300ms;
            }
        }
        &-svg-bluesapphire{
            [stroke="#004F98"] {
                stroke: currentColor;
            }
            [fill="#004F98"] {
                fill: currentColor;
            }

            &:hover{
                [stroke]:not([stroke="none"]):not([stroke="#004F98"]){
                    stroke: $bluesapphire;
                }
                [fill]:not([fill="none"]):not([fill="#004F98"]){
                    fill: $bluesapphire;
                }
            }
            
        }
    }

    .group[aria-selected="true"]{
        .group-aria-selected\:svg-bluesapphire{
            [stroke]:not([stroke="none"]):not([stroke="#004F98"]){
                stroke: $bluesapphire;
            }
            [fill]:not([fill="none"]):not([fill="#004F98"]){
                fill: $bluesapphire;
            }
        }
    }

    .languages-actived{
        pointer-events: none;
        position: relative;
        &::after{
            content: "";
            z-index: 1;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #686868B2;
        }
    }

    
    .swiper{
        &[data-slide="slideLogos"]{
            transition-timing-function:linear!important;
            .swiper-slide{
                width: 204px;
                height: 44px;
                // background-color: #fff;
                // border-radius: 44px;
                flex: none;
                justify-content: center;
                align-items: center;
                display: flex;
                overflow: hidden;

                a{
                    width: auto;
                    height: 44px;
                    justify-content: center;
                    align-items: stretch;
                    padding: 0;
                    display: flex;
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
            }
        }
        &-pagination{
            --swiper-pagination-color: #F11B23;
            --swiper-pagination-bullet-inactive-color: #0084CE;
            --swiper-pagination-bullet-inactive-opacity: 1;
            --swiper-pagination-bullet-size: 10px;

            @include rpmin(lg){
                --swiper-pagination-bullet-size: 16px;
            }

            .swiper-pagination-bullet{
                position: relative;
                border: 1px solid #FFF;
                transition: .16s all ease-in-out;
            }
            &.swiper-pagination-left{
                margin-top: 2rem;
                position: relative;
                bottom: 0 !important;
                left: 0 !important;
                transform: none !important;
            }
            &.swiper-pagination-center{
                position: relative;
                bottom: 0 !important;
                margin-top: 0.5rem;
            }
            &.swiper-pagination-right{
                margin-top: 0.5rem;
                margin-left: auto;
                position: relative;
                bottom: 0 !important;
                left: 0 !important;
                transform: none !important;
            }
        }

        &-button-prev, &-button-next{
            &::after{
                content: none ;
            }
    
            width: 32px ;
            height: 32px ;
            border-radius: 100px ;

            img{
                width: 25%;
                height: auto;

                @include rpmin(lg){
                    width: 15%;
                }
            }

            background-color: rgba(245, 245, 245, 0.5);
            border: 1px solid #F5F5F5;

            display: flex;
            justify-content: center;
            align-items: center;

            transition: .3s all ease-in-out;


            &:hover{
                box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
            }

            @include rpmin(lg){
                width: 50px;
                height: 50px;
                background-color: #F5F5F5;
            }
        }
    }

    // .menu-actived{
    //     position: relative;
    //     &>a{
    //         color: $red;
    //         border-left: 2px solid $red;
    //     }
    // }
    // .submenu-actived{
    //     &>a{
    //         color: $red;
    //     }
    // }
    .tooltip {
        cursor: context-menu;
        pointer-events: none;
        opacity: 0.6;
    }

    .pagination{
        width: 100%;
        list-style-type: none;

        --swArrow: 9px;
        --shArrow: 14x;

        --text: #737373;
        --textArrow: #000000;
        --bgArrow: transparent;

        --textHover: #F11B23;

        ul{
            list-style-type: none;
            display: flex;
            align-items: center;
            justify-content: center;

            li{
                display: flex;
                justify-content: center;
                align-items: center;

                cursor: pointer;
                user-select: none;

                transition: .3s ease-in-out;

                width: var(--wh, auto);
                height: var(--wh, auto);

                font-size: var(--size, inherit);
                line-height: var(--wh, 1.2);

                color: var(--text, #000);
                background-color: var(--bgBtn, transparent);

                &:not(.is-actived):hover {
                    color: var(--textHover);
                    background-color: var(--bgBtnHover);
                }

                a{
                    display: inline-block;
                }

                &.is-actived {
                    cursor: initial;
                    color: var(--textHover, #000);
                    background-color: var(--bgBtnHover, transparent);
                    font-weight: 700;
                }
            }

            li.next,
            li.prev {
                background-color: var(--bgArrow);
                transition: .3s ease-in-out;
        
                display: flex;
                justify-content: center;
                align-items: center;
            
                &>a{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                svg {
                    width: var(--swArrow, auto);
                    height: var(--shArrow, auto);
                    
                    [fill]:not([fill="none"]){
                        fill: var(--textArrow, #000);
                    }
                    [stroke]:not([stroke="none"]){
                        stroke: var(--textArrow, #000);
                    }
                }
        
                &:not(.btn-hidden):hover {
                    background-color: var(--bgArrowHover);

                    svg {
                        [fill]:not([fill="none"]){
                            fill: var(--textArrowHover, #000);
                        }
                        [stroke]:not([stroke="none"]){
                            stroke: var(--textArrowHover, #000);
                        }
                    }
                }
        
                &.btn-hidden {
                    // display: none;
                    cursor: initial;
                    opacity: .3;
                }
            }
        
            // li.prev.btn-hidden + li{
            //     margin-left: 0;
            // }
        
            li.prev {
                display: flex;
                align-items: center;
                justify-content: center;
        
                a{
                    display: flex;
                    justify-content: center;
                    align-items: center;
        
                    margin-right: 40px;
                }
                svg {
                    transform: rotate(180deg);
                }
            }
            li.next {
                display: flex;
                align-items: center;
                justify-content: center;
        
                a{
                    width: 100%;
                    height: 100%;
                    
                    display: flex;
                    justify-content: center;
                    align-items: center;
        
                    margin-left: 40px;
                }
            }
        
        
            li.dot{
                user-select: none;
                pointer-events: none;
        
                background-color: var(--bgBtn);
            }
        
            li + li {
                margin-left: 10px;
                @include rpmin(lg){
                    margin-left: 23px;
                }
            }
        }
    }
}